import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';
import { Routes, Route } from 'react-router-dom';
import { setActiveNavID } from '../../app/appNavConfigSlice';
import SalesSideNav from './SalesSideNav';
import UploadedSalesList from './UploadedSalesList';


function Sales() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setActiveNavID('uploadedSales'));
    })
    return (
        <Container fluid>
            <Grid>
                <Grid.Column stretched width={2}>
                    <SalesSideNav />
                </Grid.Column>
                <Grid.Column stretched width={14}>
                    <Routes>
                        <Route path='/' element={<UploadedSalesList />} exact />
                    </Routes>
                </Grid.Column>
            </Grid>
        </Container>
    )
}
export default Sales;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    submitted: false,
    loading: false,
    error: false,
    errorMessage: '',
    errorMessageData: ''
}

export const uploadStockSlice = createSlice({
    name: 'uploadStock',
    initialState,
    reducers: {
        uploadStockInProgress: () => {
            return {
                ...initialState,
                loading: true
            }
        },
        uploadStockProcessed: (state, { payload }) => {
            return {
                ...initialState,
                ...payload,
                submitted: !payload.error
            }
        },
        uploadStockOperationCompleted: () => {
            return initialState
        }

    }
})

export const { uploadStockInProgress, uploadStockProcessed, uploadStockOperationCompleted } = uploadStockSlice.actions
export default uploadStockSlice.reducer
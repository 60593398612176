import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    submitted: false,
    loading: false,
    error: false,
    errorMessage: '',
    errorMessageData: ''
}

export const uploadSaleSlice = createSlice({
    name: 'uploadSale',
    initialState,
    reducers: {
        uploadSaleInProgress: () => {
            return {
                ...initialState,
                loading: true
            }
        },
        uploadSaleProcessed: (state, { payload }) => {
            return {
                ...initialState,
                ...payload,
                submitted: !payload.error
            }
        },
        uploadSaleOperationCompleted: () => {
            return initialState
        }

    }
})

export const { uploadSaleInProgress, uploadSaleProcessed, uploadSaleOperationCompleted } = uploadSaleSlice.actions
export default uploadSaleSlice.reducer
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeSideNavID: 'uploadedStocks'
}

export const stocksSideNavSlice = createSlice({
    name: "stocksSideNav",
    initialState,
    reducers: {
        setActiveSideNavID: (state, { payload }) => {
            state.activeSideNavID = payload;
        }
    }
})

export const {setActiveSideNavID} =stocksSideNavSlice.actions;

export default stocksSideNavSlice.reducer
import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    errorMessageData: '',
    uploadedSales: []
}
export const uploadedSalesSlice = createSlice({
    name: 'uploadedSales',
    initialState,
    reducers: {
        uploadedSalesInprogress: (state)=>{
            return {
                ...state,
                loading: true
            }
        },
        uploadedSalesListProcessed: (state, {payload}) =>{
            return {
                ...state,
                loading: false,
                ...payload
            }
        }
    }
})
export const {  uploadedSalesInprogress, uploadedSalesListProcessed } = uploadedSalesSlice.actions;
export default uploadedSalesSlice.reducer;
import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Message, Header, Icon, Popup, Button } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import { getDppAPI, DateRangeFilter,DataTable } from 'hpp-fe-common';
import {
    loadInvoicesInprogress,
    loadInvoicesListProcessed
} from './invoicesSlice';
import { setActiveSideNavID } from './invoicesSideNavSlice'
import { signOut } from '../auth/authSlice';
import ViewInvoiceDetailsModal from './view-invoice-details/ViewInvoiceDetailsModal';

const columns = [
    { name: 'Company Name', selector: row => row.companyName, sortable: true },
    { name: 'Created Date', selector: row => row.createdDate, sortable: true },
    { name: 'Invoice Date', selector: row => row.invoiceDate, sortable: true },
    { name: 'Invoice Number', selector: row => row.invoiceNumber, sortable: true },
    { name: 'Document Type', selector: row => row.type, sortable: true },
    { name: 'Status', selector: row => row.status, sortable: true },
    { name: 'Total Value', selector: row => row.totalValue, sortable: true },
    { name: 'Remarks', selector: row => row.remarks, cell:row=>row.remarks, sortable: false }
]

const filterTypes = [
    { key: 'companyName', text: 'By Company Name', value: 'companyName' },
    { key: 'type', text: 'By Document Type', value: 'type' },
    { key: 'status', text: 'By Status', value: 'status' }
]

const dateRangeFilterOptions = [
    { key: "last90Days", text: 'Last 90 Days', value: "last90Days" },
    { key: "thisMonth", text: 'This Month', value: "thisMonth" },
    { key: "thisQuater", text: 'This Quater', value: "thisQuater" },
    { key: "lastQuater", text: 'Last Quater', value: "lastQuater" },
    { key: "customRange", text: 'Custom Range', value: "customRange" }
]

function InvoicesList() {

    const dispatch = useDispatch();

    const { loading, error, errorMessage, errorMessageData, invoices } = useSelector(state => state.invoices);

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    // Local state to manage data range filter
    const [filterDateRange, setFilterDateRange] = useState({});
    const [selectedDateRangeFilterOption, setSelectedDateRangeFilterOption] = useState('thisQuater')

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setSelectedRows([])
        setToggleClearRows(!toggledClearRows);
    }

    useEffect(() => {
        switch (selectedDateRangeFilterOption) {
            case "last90Days":
                const minDate = DateTime.now().minus({ days: 90 }).toFormat('yyyy-MM-dd')
                const maxDate = DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd');
                setFilterDateRange({
                    startDate: minDate,
                    endDate: maxDate
                })
                break;
            case "thisMonth":
                const startDateOfMonth = DateTime.local().startOf('month').toFormat('yyyy-MM-dd')
                const endDateOfMonth = DateTime.local().endOf('month').plus({ days: 1 }).toFormat('yyyy-MM-dd')
                setFilterDateRange({
                    startDate: startDateOfMonth,
                    endDate: endDateOfMonth
                })
                break;

            case "thisQuater":
                const startDateOfQuater = DateTime.local().startOf('quarter').toFormat('yyyy-MM-dd')
                const endDateOfQuater = DateTime.local().endOf('quarter').plus({ days: 1 }).toFormat('yyyy-MM-dd')
                setFilterDateRange({
                    startDate: startDateOfQuater,
                    endDate: endDateOfQuater
                })
                break;

                case "lastQuater":
                const today = DateTime.local();
                const currentQuarter = Math.floor((today.month - 1) / 3) + 1;
                const startOfLastQuarter = today.startOf('year').plus({ months: (currentQuarter - 2) * 3 }).toFormat('yyyy-MM-dd');
                const endOfLastQuarter = today.startOf('year').plus({ months: (currentQuarter - 1) * 3 }).toFormat('yyyy-MM-dd');
                setFilterDateRange({
                    startDate: startOfLastQuarter,
                    endDate: endOfLastQuarter
                });
                break;
                
            case "customRange":
                break;

            default:
                break;
        }
    }, [setFilterDateRange, selectedDateRangeFilterOption])

    const refresh = useCallback(() => {

        const { endDate, startDate } = filterDateRange

        if (endDate && startDate) {
            dispatch(loadInvoicesInprogress());

            getDppAPI().get(`/api_v1/invoice/get-all?type=purchase&portal=nd&startDate=${startDate}&endDate=${endDate}`)
                .then(({ data }) => {
                    if (data.authenticated === false) {
                        return dispatch(signOut({
                            error: true,
                            errorMessage: data.errorMessage,
                            errorMessageData: data.errorMessageData
                        }))
                    }

                    const newInvoicesData = data.invoices.map((invoice) => {
                        if (invoice.status === "rejected" || invoice.status === "pending" || invoice.status === "canceled") {
                            return { ...invoice }
                        } else {
                            return { ...invoice, remarks: "" }
                        }

                    })
                    const newData = { ...data, invoices: newInvoicesData }
                    dispatch(loadInvoicesListProcessed(newData))
                })
                .catch(error => {
                    dispatch(loadInvoicesListProcessed({
                        error: true,
                        errorMessage: 'Error in loading company data. Please contact system administrator',
                        errorMessageData: error.toString()
                    }))
                })

        }
    }, [dispatch, filterDateRange])

    const refreshViaButton = () => {
        handleClearRows();
        refresh();
    }

    useEffect(() => {
        dispatch(setActiveSideNavID('uploadedInvoices'));

        refresh();
    }, [dispatch, refresh])



    return (
        <Segment loading={loading}>
            <Header as='h3'>
                <Icon name='th list' />
                <Header.Content>
                    Purchase Invoices
                </Header.Content>
                <DateRangeFilter
                    setFilterDateRange={setFilterDateRange}
                    options={dateRangeFilterOptions}
                    setOption={setSelectedDateRangeFilterOption}
                    selectedOption={selectedDateRangeFilterOption}
                    maxDateRange={92}
                />
            </Header>
            <Popup position='left center' content='Refresh data' trigger={<Button color='black' onClick={refreshViaButton} size='massive' style={{ position: 'fixed', right: '10px', bottom: '30px', zIndex: 100 }} circular icon='sync alternate' />} />
            {
                error &&
                <Message negative>
                    <Message.Header>Error loading upload data</Message.Header>
                    <p>{errorMessage}</p>
                    <p>{errorMessageData}</p>
                </Message>
            }
            {
                (Array.isArray(selectedRows) && selectedRows.length !== 0) && <Button onClick={handleClearRows}><Icon name='close' />Clear Selection</Button>
            }
            {
                (Array.isArray(selectedRows) && selectedRows.length === 1) && <ViewInvoiceDetailsModal selectedInvoice={selectedRows[0]} handleClearRows={handleClearRows} />
            }


            {

                <DataTable data={invoices} columns={columns} filterTypes={filterTypes}  showTotalFor='totalValue' additionalProps={{
                    selectableRows: true,
                    onSelectedRowsChange: handleChange,
                    clearSelectedRows: toggledClearRows,
                }} />
            }

        </Segment>
    );
}

export default InvoicesList;
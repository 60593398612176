import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    activeSideNavID: 'uploadedSales'
}
export const salesSideNavSlice = createSlice({
    name: "salesSideNav",
    initialState,
    reducers: {
        setActiveSideNavID: (state, { payload }) => {
            state.activeSideNavID = payload;
        }
    }
})
export const { setActiveSideNavID } = salesSideNavSlice.actions;
export default salesSideNavSlice.reducer
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Message, Header, Icon, Popup, Button } from 'semantic-ui-react';
import { DataTable, getDppAPI } from 'hpp-fe-common';
import { DateTime } from 'luxon';
import { uploadedSalesInprogress, uploadedSalesListProcessed } from './uploadedSalesListSlice'
import { setActiveSideNavID } from './salesSideNavSlice'
import { signOut } from '../auth/authSlice';

const columns = [
    { name: "Uploaded System User Name", selector: row => row.uploadedSystemUser, sortable: true },
    {
        name: "Uploaded Date and Time",
        selector: row => DateTime.fromISO(row.uploadedDate).toFormat('yyyy/MM/dd HH:mm:ss'),
        sortable: true
    },
    {
        name: "Verification Status",
        selector: row => row.verificationStatus && row.verificationStatus.charAt(0).toUpperCase() + row.verificationStatus.slice(1),
        sortable: true
    },
    { name: "Report Type", selector: row => row.reportType && row.reportType.charAt(0).toUpperCase() + row.reportType.slice(1), sortable: true },
    { name: "Remark", selector: row => row.remark, cell: row => row.remark, sortable: true },
]

const filterTypes = [
    { key: 'uploadedSystemUser', text: 'By Uploaded System User', value: 'uploadedSystemUser' },
    { key: 'verificationStatus', text: 'By Status', value: 'verificationStatus' },
    { key: 'reportType', text: 'By Report Type', value: 'reportType' }
]

function UploadedSalesList() {
    const dispatch = useDispatch();

    const { loading, error, errorMessage, errorMessageData, uploadedSales } = useSelector(state => state.uploadedSales);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const handleClearRows = () => {
        setSelectedRows([])
        setToggleClearRows(!toggledClearRows);
    }

    const refresh = useCallback(() => {
        dispatch(uploadedSalesInprogress());
        getDppAPI().get('/api_v1/ndSale/get-all?portal=nd')
            .then(({ data }) => {
                if (data.authenticated === false) {
                    return dispatch(signOut({
                        error: true,
                        errorMessage: data.errorMessage,
                        errorMessageData: data.errorMessageData
                    }))
                }
                dispatch(uploadedSalesListProcessed(data))
            })
            .catch((error) => {
                dispatch(uploadedSalesListProcessed({
                    error: true,
                    errorMessage: 'Error in loading user data. Please contact system administrator',
                    errorMessageData: error.toString()
                }))
            })
    }, [dispatch])

    const refreshViaButton = () => {
        handleClearRows();
        refresh();
    }

    useEffect(() => {
        dispatch(setActiveSideNavID('uploadedSales'));
        refresh();
    }, [dispatch, refresh])

    return (
        <Segment loading={loading}>
            <Header as='h3'>
                <Icon name='th list' />
                <Header.Content>
                    Uploaded Sales Reports
                </Header.Content>
            </Header>
            <Popup position='left center' content='Refresh data' trigger={<Button color='black' onClick={refreshViaButton} size='massive' style={{ position: 'fixed', right: '10px', bottom: '30px', zIndex: 100 }} circular icon='sync alternate' />} />
            {
                error &&
                <Message negative>
                    <Message.Header>Error loading uploaded sales data</Message.Header>
                    <p>{errorMessage}</p>
                    <p>{errorMessageData}</p>
                </Message>
            }
            {
                (Array.isArray(selectedRows) && selectedRows.length !== 0) && <Button onClick={handleClearRows}><Icon name='close' />Clear Selection</Button>
            }
            {
                <DataTable data={uploadedSales} columns={columns} filterTypes={filterTypes} additionalProps={{
                    selectableRows: true,
                    onSelectedRowsChange: handleChange,
                    clearSelectedRows: toggledClearRows,
                }} />
            }
        </Segment>
    );
}
export default UploadedSalesList;
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';
import { Routes, Route } from 'react-router-dom';
import { setActiveNavID } from '../../app/appNavConfigSlice';
import StocksSideNav from './StocksSideNav';
import UploadedStocksList from './UploadedStocksList';

function Stocks() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveNavID('uploadedStocks'));
    })
    return (
        <Container fluid>
            <Grid>
                <Grid.Column stretched width={2}>
                    <StocksSideNav />
                </Grid.Column>

                <Grid.Column stretched width={14}>
                    <Routes>
                        <Route path='/' element={<UploadedStocksList />} exact />
                    </Routes>
                </Grid.Column>
            </Grid>
        </Container>
    )
}


export default Stocks;
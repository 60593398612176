import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function InvoicesSideNav() {
    const { activeSideNavID } = useSelector(state => state.invoicesSideNav)

    return (
        <Menu fluid vertical>
            <Menu.Item>
                <Menu.Header>Invoice Records</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        name='Purchase Invoices'
                        active={activeSideNavID === 'uploadedInvoices'}
                        as={Link}
                        to={'/invoices'}
                    />
                </Menu.Menu>
            </Menu.Item>
        </Menu>
    )
}

export default InvoicesSideNav;
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UploadSaleModal from './upload-sale/UploadSaleModal';


function SalesSideNav() {
    const { activeSideNavID } = useSelector(state => state.salesSideNav)
    return (
        <Menu fluid vertical>
            <Menu.Item>
                <Menu.Header>Upload Sales Reports</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        name='Uploaded Sales Reports'
                        active={activeSideNavID === 'uploadedSales'}
                        as={Link}
                        to={'/uploadedSales'}
                    />
                    <UploadSaleModal />
                </Menu.Menu>
            </Menu.Item>
        </Menu>
    )
}
export default SalesSideNav;
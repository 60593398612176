import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UploadStockModal from './upload-stock/UploadStockModal';

function StocksSideNav() {
    const { activeSideNavID } = useSelector(state => state.stocksSideNav)

    return (
        <Menu fluid vertical>
            <Menu.Item>
                <Menu.Header>Upload Stocks Reports</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        name='Uploaded Stocks Reports'
                        active={activeSideNavID === 'uploadedStocks'}
                        as={Link}
                        to={'/uploadedStocks'}
                    />
                    <UploadStockModal />
                </Menu.Menu>
            </Menu.Item>
        </Menu>
    )
}

export default StocksSideNav;
import React, { useState } from 'react'
import {
    Button,
    Modal,
    Message,
    Icon,
    Header,
    Segment,
    Form,
    Dropdown
} from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux';
import { getDppAPI, getCommonAPI } from 'hpp-fe-common';
import { DateTime } from 'luxon';
import { uploadSaleInProgress, uploadSaleProcessed, uploadSaleOperationCompleted } from './uploadSaleSlice'
import { signOut } from '../../auth/authSlice';


const UploadSale = (props) => {
    const dispatch = useDispatch();

    const { loading, error, errorMessage, errorMessageData, submitted } = useSelector(state => state.uploadSale);
    const { companyNameEntered } = JSON.parse(window.localStorage.getItem('user'));

    const [selectedFile, setSelectedFile] = useState(undefined)
    const [selectedReportType, setSelectedReportType] = useState('');


    const reportTypeOptions = [
        { key: "detailed", text: 'Detailed Report', value: "detailed" },
        { key: "summary", text: 'Summary Report', value: "summary" }
    ]

    const handleModalClose = () => {
        dispatch(uploadSaleOperationCompleted())
        props.handleClose()
    }

    const removeFile = () => {
        setSelectedFile(undefined)
    }

    // Remove spaces and convert to lowercase
    const formatWord = (word) => {
        var formattedWord = word.toLowerCase().replace(/\s+/g, '-');
        return formattedWord
    }

    const handleReportType = (e, { value }) => {
        setSelectedReportType(value)
    }

    const handleUpload = async () => {
        try {
            dispatch(uploadSaleInProgress());
            const timestamp = DateTime.now().toFormat('yyyyMMddHHmmss')

            const customizedDocID = formatWord(companyNameEntered) + timestamp
            const uploadFormData = new FormData();
            uploadFormData.append('file', selectedFile);

            const uploadResponse = await getCommonAPI().post(
                `/api_v1/files/upload/single-file?fileType=nd-sale-report&fileDocID=${customizedDocID}&fileExtension=xlsx&portal=nd`,
                uploadFormData,
                {
                    timeout: 120000,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (uploadResponse.data.authenticated === false) {
                return dispatch(signOut({
                    error: true,
                    errorMessage: uploadResponse.data.errorMessage,
                    errorMessageData: uploadResponse.data.errorMessageData,
                }));
            }

            if (uploadResponse.data?.error) {
                return dispatch(uploadSaleProcessed({
                    error: true,
                    errorMessage: uploadResponse.data.errorMessage,
                    errorMessageData: uploadResponse.data.errorMessageData,
                }));
            }

            if (!uploadResponse.data?.key) {
                return dispatch(uploadSaleProcessed({
                    error: true,
                    errorMessage: "Bad response from server. File upload not successful",
                    errorMessageData: 'Please contact the system administrator',
                }));
            }

            // Submit stock
            const stockResponse = await getDppAPI().post('/api_v1/ndSale/add-one?type=nd-sale-report', {
                ndSaleKey: uploadResponse.data.key,
                reportType: selectedReportType
            });

            if (stockResponse.data.authenticated === false) {
                return dispatch(signOut({
                    error: true,
                    errorMessage: stockResponse.data.errorMessage,
                    errorMessageData: stockResponse.data.errorMessageData,
                }));
            }

            dispatch(uploadSaleProcessed(stockResponse.data));

        } catch (error) {
            dispatch(uploadSaleProcessed({
                error: true,
                errorMessage: "Unidentified error. Please contact the system administrator",
                errorMessageData: error.toString(),
            }));
        }
    };

    // Render success
    if (submitted) return (
        <>
            <Modal.Content >
                <Message success>
                    <Message.Header>Success</Message.Header>
                    <p>Sales Report successfully submitted</p>
                </Message>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleModalClose} >
                    <Icon name='remove' /> Close
                </Button>
            </Modal.Actions>

        </>
    )

    return (
        <>
            <Modal.Content>
                <Segment loading={loading} basic>

                    <Message>
                        <Message.Header>Important</Message.Header>
                        <Message.List>
                            <Message.Item>Supported file types are XLS and XLSX.</Message.Item>
                            <Message.Item>The columns 'Product Name,' 'Description,' and 'Quantity' are mandatory.</Message.Item>
                            <Message.Item>Uploaded file size should not exceed 25MB.</Message.Item>
                            <Message.Item>Only one sale report can be uploaded at a time. For multiple sale reports, please repeat the process.</Message.Item>
                            <Message.Item>Only a maximum of 5 sale reports can be uploaded in one day.</Message.Item>
                        </Message.List>
                    </Message>

                    {
                        error &&
                        <Message negative>
                            <Message.Header>Error in uploading sales data</Message.Header>
                            <p>{errorMessage}</p>
                            <p>{errorMessageData}</p>
                        </Message>
                    }
                    <Form>
                        <Form.Field>
                            <span style={{ marginRight: 10 }}>{'Select Report Type'}</span>
                            <Dropdown
                                basic
                                placeholder='Select Report Type'
                                selection
                                options={reportTypeOptions}
                                onChange={handleReportType}
                                value={selectedReportType}
                            />
                        </Form.Field>
                    </Form>
                    {
                        selectedFile !== undefined ?
                            <Message
                                onDismiss={removeFile}
                                content={selectedFile?.name}
                                color="green"
                            />
                            :
                            <>
                                <Header icon textAlign='center' size='small' as="label" htmlFor="file">
                                    <Icon name='upload' />
                                    Select Sales Report
                                </Header>

                                <input hidden accept=".xls, .xlsx" type="file" id="file" style={{ display: "hidden" }} onChange={(e) => setSelectedFile(e.target.files[0])} />
                            </>
                    }
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={handleUpload} disabled={selectedFile === undefined || selectedReportType === ''}>
                    Upload
                </Button>
                <Button onClick={handleModalClose}>Close</Button>
            </Modal.Actions>

        </>
    )
}

export default UploadSale
import React, { useState } from 'react'
import {
    Menu,
    Modal
} from 'semantic-ui-react';
import UploadSale from './UploadSale';

function UploadSaleModal() {
    const [open, setOpen] = useState(false)

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    return (
        <Modal
            onClose={handleClose}
            open={open}
            size="small"
            closeOnDimmerClick={false}
            trigger={<Menu.Item onClick={handleOpen}>Upload New Sales Report</Menu.Item>}
        >
            <Modal.Header>Upload Sales Report</Modal.Header>
            <UploadSale handleClose={handleClose} />
        </Modal>
    )
}

export default UploadSaleModal;